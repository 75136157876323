// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-jsx": () => import("./../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-cadastro-jsx": () => import("./../src/pages/cadastro.jsx" /* webpackChunkName: "component---src-pages-cadastro-jsx" */),
  "component---src-pages-cadastro-ong-jsx": () => import("./../src/pages/cadastro-ong.jsx" /* webpackChunkName: "component---src-pages-cadastro-ong-jsx" */),
  "component---src-pages-contato-jsx": () => import("./../src/pages/contato.jsx" /* webpackChunkName: "component---src-pages-contato-jsx" */),
  "component---src-pages-eventos-jsx": () => import("./../src/pages/eventos.jsx" /* webpackChunkName: "component---src-pages-eventos-jsx" */),
  "component---src-pages-history-jsx": () => import("./../src/pages/history.jsx" /* webpackChunkName: "component---src-pages-history-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-parceiros-jsx": () => import("./../src/pages/parceiros.jsx" /* webpackChunkName: "component---src-pages-parceiros-jsx" */),
  "component---src-pages-password-jsx": () => import("./../src/pages/password.jsx" /* webpackChunkName: "component---src-pages-password-jsx" */),
  "component---src-pages-pdf-jsx": () => import("./../src/pages/pdf.jsx" /* webpackChunkName: "component---src-pages-pdf-jsx" */),
  "component---src-pages-politica-de-privacidade-jsx": () => import("./../src/pages/politica-de-privacidade.jsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-jsx" */)
}

